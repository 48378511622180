
































import { Vue, Component } from 'vue-property-decorator';
import AllianzProcessManualReviewRequestSentViewModel
  from '@/vue-app/view-models/components/contract-savings/allianz/allianz-process-manual-review-request-sent-view-model';

@Component({ name: 'AllianzProcessManualReviewRequestSent' })
export default class AllianzProcessManualReviewRequestSent extends Vue {
  allianz_process_manual_review_request_sent_view_model = Vue.observable(
    new AllianzProcessManualReviewRequestSentViewModel(),
  );
}
